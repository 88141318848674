import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import OrderPlacedBanner from '../components/order-placed-banner';
import OrderSummary from '../components/order-summary';
import { externalLinks } from '../helpers/navigation';
import { getSearchParam } from '../helpers/url';
import { selectElectronicSignments, selectEntityUserDetails } from '../store/selectors/entities';
import styles from '../styles/pages/checkout-success.module.scss';
import { getInstanceName } from '../helpers/utils';
import { toggleLiveChat } from '../store/actions';
import { selectShowVatAmount } from '../store/selectors';
import getOrderById from '../services/api/actions/getOrderById';
import Loader from '../components/loader';

const ORDER_STATUSES = {
  PENDING: 'Pending',
  PAID: 'Paid',
  ON_HOLD: 'On hold',
  PROCESSING: 'Processing',
  CANCELED: 'Canceled',
  REFUNDED: 'Refunded',
  BLOCKCHAIN_PENDING: 'Blockchain Pending',
  WITH_ERROR: 'Error',
  EXPIRED: 'Expired',
};

const query = graphql`
  query {
    bgLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

let counter = 0;

const CheckoutSuccess = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userDetails = useSelector(selectEntityUserDetails);
  const showVat = useSelector(selectShowVatAmount);
  const electronicSignments = useSelector(selectElectronicSignments);

  const [orderStatus, setOrderStatus] = useState(null);
  const [orderMessage, setOrderMessage] = useState(null);

  const isClient = typeof window === 'object';
  const myPortalTxt = 'My Portal';
  const data = useStaticQuery(query);

  const orderInfo = isClient ? JSON.parse(localStorage.getItem('boughtProducts')) || {} : {};

  const userName = userDetails && userDetails.firstName;

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.checkoutSuccess' });

  const orderResult = isClient && getSearchParam('complete', window.location.href);

  const getOrderMessage = () => {
    if (isClient && orderResult) {
      if (orderResult.toLowerCase() === 'unknown' || orderResult.includes('gateway')) {
        return 'processing';
      }
      if (orderResult === 'approved' || orderResult === 'completed') {
        return 'processed';
      }
      return orderResult;
    }
    return 'processing';
  };

  const successStatuses = ['approved', 'completed', 'Paid'];

  const orderResultMessage = orderStatus || getOrderMessage();

  const isOrderPlaced = successStatuses.includes(orderResult);

  useEffect(() => {
    if (!isOrderPlaced) {
      dispatch(toggleLiveChat(true));
    }
  });

  const redirectToBeBackOffice = () => {
    if (isClient) {
      localStorage.removeItem('audit-trial-id');
    }
    window.location.href = 'https://bebackoffice.com/';
  };

  const redirectToAlternativePm = (e) => {
    e.preventDefault();
    window.location.href = externalLinks.alternativePaymentMethod;
  };

  //! 1 START RETRY LOGIC
  useEffect(() => {
    if (!successStatuses.includes(orderResult)) {
      // eslint-disable-next-line no-use-before-define
      checkAndRequest();
    }
  }, [orderResult]);

  //! 2 DO RETRY 5 TIMES
  const checkAndRequest = () => {
    if (counter > 5) {
      return setOrderStatus('declined');
    }
    // eslint-disable-next-line no-use-before-define
    return setTimeout(retryOrderInfo, 3000);
  };

  //! 3 RETRY REQUEST AND RESPONSE CHECK
  const retryOrderInfo = async () => {
    const id = orderInfo && orderInfo.order ? `/${orderInfo.order}` : '';

    if (!id) {
      return setOrderStatus('declined');
    }

    const { payload } = dispatch(getOrderById.withQuery(id).action());

    if (payload) {
      const success = payload.data.status === 'Paid';

      if (success) {
        return setOrderStatus('success');
      }

      const errorStatus = [ORDER_STATUSES.CANCELED, ORDER_STATUSES.WITH_ERROR].includes(
        payload.data.status
      );

      if (errorStatus) {
        setOrderMessage(payload.data.message);
        setOrderStatus('failed');
      }
    } else {
      // eslint-disable-next-line no-plusplus
      counter++;
      checkAndRequest();
    }

    return null;
  };

  const renderOrderInfo = () => {
    if (!orderStatus && !successStatuses.includes(orderResult)) {
      return <Loader isLoading isSmall={false} />;
    }

    if (orderStatus === 'declined') {
      return (
        <div className={styles.info}>
          <h4>Sorry we are still waiting for your payment</h4>
          <span>
            Your order has <strong>NOT</strong> been completed yet, we are still waiting to receive
            the funds. If your subscriptions does <strong>NOT</strong> get activated within the next
            5 minutes, please retry the payment again. In the meantime you can check your{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" onClick={redirectToBeBackOffice}>
              ORDER STATUS HERE!
            </a>
            <div className={styles.note}>
              <strong>NOTE:</strong>
              Bitcoin payment confirmation may take up to 1 hour.
            </div>
          </span>
        </div>
      );
    }

    return (
      <OrderSummary
        isOrderPlaced={isOrderPlaced}
        showVat={showVat}
        electronicSignments={electronicSignments}
      />
    );
  };

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <div className={styles.banner}>
            <OrderPlacedBanner
              userName={userName}
              orderResult={orderResultMessage}
              orderMessage={orderMessage}
            />
          </div>

          <div style={{ position: 'relative' }}>
            <BackgroundImage
              Tag="div"
              className={styles.summaryBg}
              fluid={[data.bgLeft.childImageSharp.fluid, data.bgRight.childImageSharp.fluid]}
              backgroundColor="transparent"
            >
              <div className={styles.summaryBgOverlay} />
              <div className={styles.summary}>{renderOrderInfo()}</div>
            </BackgroundImage>
          </div>

          {isOrderPlaced && (
            <div className={styles.action}>
              <Button
                className={styles.button}
                size="large"
                withArrow
                component="button"
                onClick={redirectToBeBackOffice}
              >
                {myPortalTxt}
              </Button>
            </div>
          )}

          {process.env.GATSBY_INSTANCE_NAME === 'befreedom' && orderStatus === 'failed' && (
            <div className={styles.action}>
              <Button
                className={styles.button}
                size="large"
                component="button"
                onClick={redirectToAlternativePm}
              >
                Checkout with alternative payment method
              </Button>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
};

export default CheckoutSuccess;
